import React from 'react';
export const Header = (props) => {
  const handleContactClick = (cat) => {
    if (props.onContactClick) {
      props.onContactClick(cat); 
    }
  };

  return (
    <header id="header" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators ">
        {props.data ? props.data.map((slide, index) => (
          <li
            key={index}
            data-target="#header"
            data-slide-to={index}
            style={{ marginLeft: index === 0 ? "0" : "10px" }}
            className={index === 0 ? "active" : ""}
          ></li>
        )):"loading"}
      </ol>

      <div className="carousel-inner">
        {props.data ?props.data.map((slide, index) => (
          <div key={index} className={`item ${index === 0 ? "active" : ""}`}>
            <div className="intro">
              <div className="overlay">
                <div className="container border">
                  <div className="row">
                    <div className="col-lg-8 intro-text">
                      <h1>
                        {slide.title}
                        <span></span>
                      </h1>
                      <p>{slide.paragraph}</p>
                      <p>{slide.cat}</p>

                    
                      <a href='#contact'
                
                onClick={() => handleContactClick(slide.cat)}
                className=" btn btn-custom btn-lg page-scroll"
              >
                   Contact Us
              </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) :"Loading"}
      </div>

      <a className="left carousel-control" href="#header" role="button" data-slide="prev">
        <span className="sr-only">Previous</span>
      </a>
      <a className="right carousel-control" href="#header" role="button" data-slide="next">
        <span className="sr-only">Next</span>
      </a>
    </header>
  );
};
