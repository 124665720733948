import React, { useState, useEffect } from "react";

export const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveMenu(entry.target.id); 
      }
    });
  };

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.7, 
    });

    sections.forEach((section) => {
      observer.observe(section); 
    });

    return () => {
      observer.disconnect(); 
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              src="img/cl1.jpg"  height="100%"
              alt="React Landing Page"
            />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="navbar-nav navbar-right">
            <li className={activeMenu === 'about' ? 'active' : ''}>
              <a
                href="#about"
                className="page-scroll"
                onClick={() => handleMenuClick('about')}
              >
                About
              </a>
            </li>
            <li className={activeMenu === 'services' ? 'active' : ''}>
              <a
                href="#services"
                className="page-scroll"
                onClick={() => handleMenuClick('services')}
              >
                Services
              </a>
            </li>
            <li className={activeMenu === 'technology' ? 'active' : ''}>
              <a
                href="#technology"
                className="page-scroll"
                onClick={() => handleMenuClick('technology')}
              >
                Technology
              </a>
            </li>
            <li className={activeMenu === 'partners' ? 'active' : ''}>
              <a
                href="#partners"
                className="page-scroll"
                onClick={() => handleMenuClick('partners')}
              >
                Partners & Clients
              </a>
            </li>
            <li className={activeMenu === 'testimonials' ? 'active' : ''}>
              <a
                href="#testimonials"
                className="page-scroll"
                onClick={() => handleMenuClick('testimonials')}
              >
                Testimonials
              </a>
            </li>
            <li className={activeMenu === 'contact' ? 'active' : ''}>
              <a
                href="#contact"
                className="page-scroll"
                onClick={() => handleMenuClick('contact')}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
