import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Partners } from "./components/partners";
import { Technology } from "./components/technology";
// import DeepavaliAnimation from "./components/deepavali";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";  

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const handleContactClick = (category) => {
    setSelectedCategory(category); 
  };

  return (
  
      <div>
        {/* <DeepavaliAnimation /> */}
        <Navigation />
        <section ><Header data={landingPageData.Header} onContactClick={handleContactClick} /></section>
        <section id="about"><About data={landingPageData.About} /></section>
        <section id="services"><Services data={landingPageData.Services} /></section>
        <section id="technology"><Technology data={landingPageData.Technology} /></section>
        <section id="partners"><Partners data={landingPageData.Partners} /></section>
        <section id="testimonials"><Testimonials data={landingPageData.Testimonials} /></section>
        <section id="contact"><Contact data={landingPageData.Contact }  selectedCategory={selectedCategory}/></section>
        {/* <section id="Features"><Features data={landingPageData.Features} /></section> */}
        {/* <section id="gallery"><Gallery data={landingPageData.Gallery} /></section> */}
        {/* <section id="team"><Team data={landingPageData.Team} /></section> */}
      </div>
   
    
  );
};

export default App;
