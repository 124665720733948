import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-5">
            {" "}
            <img src="img/about.jpg"  className="img-responsive abt-img" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="about-text">
              <h2>About Us</h2>
              {/* {props.data ? props.data.para<p>graph : "loading..."}</p> */}
              <p class="about-par">
              At Cloudripples, we fuse innovation, technology, and problem-solving to deliver tailored, impactful solutions that help businesses thrive in the digital age. As a trusted global partner, we specialize in end-to-end software development, hybrid and native app creation, and 24x7 cloud infrastructure support, ensuring your systems are always optimized, secure, and reliable.
              </p>
              <p class="about-par">
              We can be the tech partner for your startup or SME, providing complete technology solutions while you focus on growing your business. Our Maintenance as Software Service (MASS) offers peace of mind by keeping your mobile and web applications up-to-date, secure, and compliant with app store standards.
              </p>
              <p class="about-par">
              Backed by a highly experienced team, we deliver services across cloud computing, data analytics, enterprise app development, DevOps engineering, and managed services—all designed with a customer-centric approach and built on industry-leading standards. With Cloudripples, you gain a partner committed to empowering your business with robust, scalable technology solutions that evolve as you do.
              </p>
            </div>
          </div>
        </div>

        <div className="row why-choose-us ">

        <div className="col-xs-12 col-md-7 ">
            <div className="about-text">
              
              <h3>Why Choose Us?</h3>
                <p class="about-par">
                <h5>Scalable, API-First Solutions</h5>
                We engineer scalable applications with an API-first approach and responsive user interfaces, designed to grow with your business and deliver a seamless user experience.

                <h5>Experienced Leadership team</h5>
                Our leadership team brings over 85 years (25+ years each)  of experience in solving complex challenges and delivering tailored software solutions. We understand the intricacies of engineering, project management, and end-to-end software delivery.

                <h5>End-to-End Technology Partner</h5>
                Let us be the tech partner for your startup or SME, offering a full suite of services—from requirements analysis to architecture, development, and deployment—while you focus on business growth. If you're a UI/UX design / IT consultancy, we also offer white-labelled services where we deliver solutions on your behalf to your customers.

                <h5>DevOps & 24x7 Monitoring</h5>
                Our dedicated DevOps engineers and managed services team ensure your application remains monitored and optimized 24x7x365, minimizing downtime and maximizing reliability.
                </p>
              {/* <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xs-12 col-md-5  ">
            {" "}
            <img src="img/why5.jpg" className="img-responsive" alt="" />{" "}
            <img src="img/why5.jpg" className="img-responsive" alt="" />{" "}

          </div>
      
        </div>
      </div>
    </div>
  );
};
