import React, { useEffect, useState } from "react";
import AOS from 'aos';
import axios from 'axios';
import 'aos/dist/aos.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  name: "",
  email: "",
  message: "",
  service: "",
  file: null,
};


export const Contact = (props) => {



  const { selectedCategory } = props;
  const [{ name, email, service, message, file }, setState] = useState(initialState);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");


 

  useEffect(() => {
    
    if (selectedCategory) {
      setState((prevState) => ({ ...prevState, service: selectedCategory }));
    }
  }, [selectedCategory]);

  useEffect(() => {
          // Here mention recaptcha site key 
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SECRET}`;
    script.async = true;
    document.body.appendChild(script);
  }, []);
  

  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
    AOS.refresh();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "file") {
      const selectedFile = files[0];
      if (selectedFile && selectedFile.type !== "application/pdf") {
        setFileError("Only PDF files are allowed.");
        setFileName("");
        setState((prevState) => ({ ...prevState, file: null }));
        return;
      }
      setFileError("");
      setFileName(selectedFile.name);
      setState((prevState) => ({ ...prevState, file: selectedFile }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const clearState = () => {
    setState({ ...initialState });
    setFileName("");
    setFileError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Here mention recaptcha site key 
      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SECRET, { action: 'submit' });
      sendEmail(token);
    } catch (error) {
      console.error("reCAPTCHA execution failed:", error);
      toast.error("Error verifying reCAPTCHA. Please try again.");
    }
  };

  const sendEmail = async (token) => {
    // let fileData = null;
    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   fileData = await new Promise((resolve) => {
    //     reader.onload = () => resolve(reader.result);
    //   });
    // }

    const templateParams = {
      name,
      email,
      service,
      message,
      // fileData,
      recaptchaToken: token,
    };

    try {

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-email`, templateParams, {
            headers: {
                "Content-Type": "application/json"
            }
        });               

      if (response.status === 200) {
        toast.success("Email sent successfully!");
        clearState();
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error('Email address is not verified');
    }
  };


  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <div id="contact">
        <div className="container">
        <div className="col-md-5 get-in-touch" data-aos="zoom-in-right">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
              </div>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-group">
                  <input type="text" name="name" className="form-control" placeholder="Name" required onChange={handleChange} value={name} />
                </div>
                <div className="form-group">
                  <input type="email" name="email" className="form-control" placeholder="Email" required onChange={handleChange} value={email} />
                </div>
                <div className="form-group">
                  <select name="service" className="form-control service" required onChange={handleChange} value={service}>
                    <option value="" disabled>Pick a Service</option>
                    <option value="Free Consultation">Free Consultation</option>
                    <option value="Software and Cloud Needs">Software and Cloud Needs</option>
                    <option value="DevOps & CI/CD">DevOps & CI/CD</option>
                    <option value="Software Maintenance">Software Maintenance</option>
                  </select>
                </div>
                <div className="form-group">
                  <textarea name="message" className="form-control" rows="4" placeholder="Message" required onChange={handleChange} value={message}></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">Send Message</button>
              </form>
            </div>
          </div>


          <div className="col-md-5 col-md-offset-2 contact-info" data-aos="zoom-in-left">
            <h3>CONTACT INFO</h3>
            <div className="contact-item office">

              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Head Office
                </span>
                {props.data ? props.data.address.map((line, index) => (
                  <div key={index}>{line}</div>
                ))
                  : "loading"}
              </p>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Branch Office
                </span>
                {props.data ? props.data.branchAddress.map((line, index) => (
                  <div key={index}>{line}</div>
                ))
                  : "loading"}
              </p>
            </div>
            <div className="contact-item foot-phone-email ">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>


              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Cloud Ripples Pvt Ltd{" "}
            <a href="http://www.cloudripples.io" rel="nofollow">
              Cloud Ripples
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
