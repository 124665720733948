import {React,useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';


export const Services = (props) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: false, 
    })
    AOS.refresh();
  }, []);
  return (
    <div id="services"  className="text-center">
      <div  className="container">
        <div className="section-title service-title">
          <h2>Our Services</h2>
          <p>
            At Cloudripples, we offer a comprehensive range of services to meet all your IT and business needs.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" data-aos="zoom-in">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc" data-aos="zoom-in">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
